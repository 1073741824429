import React from 'react';

import Section from '../utils/Section';

interface Props {
  heading: string;
}

export default function LandingHeader({ heading }: Props) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  const textClass = (index: number) =>
    `opacity-0 transition-opacity delay-${
      index * 100 + 100
    } duration-500 ease-in-out 
    ${show ? 'opacity-100' : ' '}
    ${index !== 0 ? 'pl-1 md:pl-4 xl:pl-6' : ''}`;

  const sentence = heading;

  return (
    <Section noXPadding noYPadding>
      <h1
        className={
          'font-extrabold text-4xl md:text-6xl xl:text-8xl flex flex-row items-center justify-center text-center fade-in m-auto flex-wrap'
        }
      >
        {sentence.split(' ').map((text, index) => (
          <span key={text} className={textClass(index)}>
            {text}
          </span>
        ))}
      </h1>
    </Section>
  );
}
