import React from 'react';

import Hero from '../../components/Landing/Hero';
import LandingHeader from '../../components/Landing/LandingHeader';
import Footer from '../../components/Landing/Footer';
import Navbar from '../../components/Landing/Navbar';
import Thanks from '../../components/Landing/Thanks';
import { isMobile } from '../../utils';

export default function LandingPage() {
  const headerHeight = '94px';
  const heroHeight = `calc(${isMobile() ? '50vh' : '60vh'} - ${headerHeight})`;
  const landingHeaderHeight = isMobile() ? `30vh` : `35vh`;

  return (
    <div className="h-full m-auto bg-white text-stone-900">
      <div className="flex items-center flex-col">
        <div className="max-w-[1700px] w-full">
          <Navbar height={headerHeight} showMenu={false} />
          <div className="relative">
            <div style={{ height: heroHeight }} className={`px-4 md:px-6 `}>
              <Hero
                largeSrc="/images/847.jpg"
                smallSrc="/images/847.jpg"
                height={heroHeight}
              />
            </div>
            <div style={{ height: landingHeaderHeight }}>
              <LandingHeader heading="Thank you" />
            </div>
          </div>

          <Thanks />

          <Footer />
        </div>
      </div>
    </div>
  );
}
