import React from 'react';

import { isMobile } from '../../utils';

import Menu from '../Menu/Menu';
import Logo from '../utils/Logo';

interface Props {
  height: string;
  showMenu?: boolean;
}

export default function Navbar({ height, showMenu = true }: Props) {
  return (
    <div
      className={`flex  ${
        isMobile()
          ? ' sticky top-0 z-50 bg-white justify-between'
          : 'justify-center'
      } items-center`}
      style={{
        height,
      }}
    >
      <Logo />
      {showMenu && isMobile() ? <Menu /> : null}
    </div>
  );
}
