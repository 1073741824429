import React from 'react';

import Section from '../utils/Section';

export default function Thanks() {
  return (
    <Section id="intro" noYPadding>
      <h4 className="text-xl md:text-2xl lg:text-3xl">
        Thank you so much to all our guests for joining us to celebrate. We
        truly appreciated your presence and are very grateful for your
        generosity; we had the time of our lives.
      </h4>
    </Section>
  );
}
