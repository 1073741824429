import React from 'react';

interface Props {
  largeSrc: string;
  height?: string;
  smallSrc?: string;
}

export default function Hero({ height, largeSrc, smallSrc }: Props) {
  const [loaded, setLoaded] = React.useState(false);

  const defaultClassName = `m-auto w-full h-full object-cover shadow-2xl saturate-50 rounded-3xl overflow-hidden ${
    loaded ? 'opacity-100' : 'opacity-0'
  } transition-all`;

  return (
    <>
      <img
        onLoad={() => setLoaded(true)}
        style={{ height }}
        className={`hidden md:block ${defaultClassName}`}
        src={largeSrc}
        alt="Emma & Alex in Westonbirt"
      />
      <img
        onLoad={() => setLoaded(true)}
        style={{ height }}
        className={`md:hidden ${defaultClassName}`}
        src={smallSrc ?? largeSrc}
        alt="Emma & Alex in Westonbirt"
      />
    </>
  );
}
