import React from 'react';

export default function Section({
  children,
  noXPadding,
  noYPadding,
  id,
}: {
  children: React.ReactNode;
  noXPadding?: boolean;
  noYPadding?: boolean;
  id?: string;
}) {
  return (
    <section
      id={id}
      className={`flex flex-col items-center justify-center text-center h-full ${
        noYPadding ? '' : 'pb-12 md:pb-24 lg:pb-32 pt-24'
      }
       ${noXPadding ? '' : 'px-6 md:px-24 lg:px-48'}`}
    >
      {children}
    </section>
  );
}
